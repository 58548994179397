import React from 'react';
import Layout from '../components/layout/Layout';
import { Link, graphql, StaticQuery } from 'gatsby';

import GreenProfile from '../images/greenprofile.png';
import ProfilePicture from '../images/profile500.jpg';
import Post from '../components/common/Post';

const IndexPage = () => (
  <Layout pageTitle="Ahmed Hadjou">
    <div>
      <section id="showcase">
        <div className="row">
          <div className="col-md-6 col-sm-6 showcase-left">
            <img
              className="profile-picture image"
              src={GreenProfile}
              alt="green overlay"
            />
            <img
              className="profile-picture overlay"
              src={ProfilePicture}
              alt="profile"
            />
          </div>
          <div className="col-md-6 col-sm-6 showcase-right">
            <h1 className="section-title" style={{ textAlign: 'left' }}>
              Full Stack Developer
            </h1>
            <p>
              Hello, my name is Ahmed. I love creating high quality websites and
              apps that use the latest and greatest tools out there to maximize
              UI and UX quality.
            </p>
            <Link
              to="/about"
              className="btn btn-default btn-lg custom-button mr-auto"
              style={{ margin: 'initial' }}
            >
              About me
            </Link>
          </div>
        </div>
      </section>
      <section id="quote">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <p>
              "The key to being a good web developer lies in having a strong
              sense of empathy, only by experiencing your product as if you were
              the person that will use it, will you make it the best it could
              be"
            </p>
            <p>- Ahmed Hadjou</p>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h2 className="section-title">Recent blog posts</h2>
            <hr className="horizontal-ruler" />
            <StaticQuery
              query={indexQuery}
              render={data => {
                let index = 0;
                return (
                  <div>
                    {data.allMarkdownRemark.edges.map(({ node }) => {
                      index++;
                      return (
                        <Post
                          key={node.id}
                          title={node.frontmatter.title}
                          slug={node.fields.slug}
                          body={node.excerpt}
                          createdAt={node.frontmatter.date}
                          tags={node.frontmatter.tags}
                          ruler={index === 3 ? false : true}
                        />
                      );
                    })}
                  </div>
                );
              }}
            />
            <Link to="/blog" className="btn btn-default btn-lg custom-button">
              More posts
            </Link>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

const indexQuery = graphql`
  query indexQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMM Do YYYY")
            tags
          }
          fields {
            slug
          }
          excerpt(pruneLength: 400)
        }
      }
    }
  }
`;

export default IndexPage;
